/* Menu.css */

.no-scroll {
  overflow-y: hidden;
}
.mega {
  height: 20px;
  width: 20px;
}
.top-number{
  font-size: 12px;
  font-weight: 500;
 position: absolute;
 right: 4%;
 top: 3%;
 color: rgb(77, 77, 77);
 font-family:"Varela Round", sans-serif;
}
.alert-message {
  font-family: "Varela Round", sans-serif;
  font-weight: 500;
  position: absolute;
  top: 2%;
  left: 38%;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #EF3A1B;
  cursor: pointer;
  text-decoration: none;
  z-index: 5;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.popup-animation {
  animation: popup 1s infinite;
}
.sidenav,
.toggle-button {
  position: relative;
  z-index: 1000;
}
.blurred {
  filter: blur(100px);
  transition: filter 0.3s ease;
}
.toggle-button {
  font-size: 29px;
  cursor: pointer;
  color: rgb(0, 0, 0);
  padding: 10px 15px;
  border: none;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}
button {
  background: white;
}
.toggle-button:hover {
  transform: scale(1.1);
}
.toggle-button.hide {
  display: none;
}
.icon-nav {
  margin-left: 14%;
  margin-top: 15%;
  display: flex;
  gap: 38px;
}
.icon-nav-open {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.icon-nav-close {
  opacity: 0;
  transform: translateX(0);
  transition: opacity 0.3s ease, transform 0.5s ease;
}
.med {
  height: 22px;
  width: 22px;
}
.seperator {
  display: flex;
  justify-content: space-between;
  padding: 40px 60px 40px 60px;
}
.logo {
  width: 100px;
  height: 68px;
}
.sidenav {
  height: 100%;
  width: 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fdfdfde1;
  overflow-x: hidden;
  transition: width 0.5s ease;
  padding-top: 60px;
  z-index: 3;
  box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 13px 0px rgba(0, 0, 0, 0.75);
}
.sidenav-open {
  width: 390px;
}
.sidenav a {
  padding: 10px 25px;
  text-decoration: none;
  font-size: 25px;
  color: rgb(89, 89, 89);
  display: block;
  transition: color 0.8s ease;
  position: block;
  transform: scale(1);
}
.controls {
  margin-left: 8%;
  margin-top: 18%;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.controls-open a {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.controls-close a {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.1s ease, transform 0.5s ease;
}
.controls a {
  font-size: 32px;
}
.sidenav a:hover {
  color: #000000;
  transform: scale(1.03);
}
.close-btn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 42px;
  z-index: 5;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.normal-nav {
  display: none;
}
.nav-link {
  padding: 0 15px;
  text-decoration: none;
  color: rgb(89, 89, 89);
  font-size: 18px;
  transition: color 0.3s ease;
}
.nav-link:hover {
  color: #000000;
}

@media screen and (min-width: 1024px) {
  .normal-nav {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .nav-link:hover {
    color: #be612b;
  }
  
  .toggle-button,
  .sidenav {
    display: none;
  }
}

/* Mobile and tablet view styles */

@media screen and (min-width: 1800px){
  .seperator {
    max-width: 1500px;
    align-items: center;
    justify-items: center;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1270px){
  .sidenav a {
    margin-top: 20px;
  }
  .normal-nav {
    display: flex;
    gap: 10px;
  }
}
@media screen and (max-width: 576px){
  .top-number{
    font-size: 10px;
    font-weight: 500;
   position: absolute;
   right: 10%;
   top: 3%;
   color: rgb(77, 77, 77);
   font-family:"Varela Round", sans-serif;
  }
  .seperator {
    display: flex;
    justify-content: space-between;
    padding: 40px 28px ;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .icon-nav {
    margin-left: 14%;
    margin-top: 10%;
    display: flex;
    gap: 38px;
  }
  .controls {
    margin-left: 8%;
    margin-top: 13%;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .controls a {
    font-size: 28px;
  }
  .med {
    height: 20px;
    width: 20px;
  }
  .seperator {
    display: flex;
    justify-content: space-between;
    padding: 28px 50px 20px 50px;
  }
  .alert-message{
    left: 33%;
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 450px) and (max-width: 768px) {
  .sidenav-open {
    width: 340px; /* Set the width when the sidenav is open */
  }
  .icon-nav {
    margin-left: 14%;
    margin-top: 10%;
    display: flex;
    gap: 38px;
  }
  .controls {
    margin-left: 8%;
    margin-top: 16%;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .controls a {
    font-size: 25px;
  }
  .med {
    height: 18px;
    width: 18px;
  }
  .seperator {
    display: flex;
    justify-content: space-between;
    padding: 33px 38px 24px 38px;
  }
  .logo {
    width: 90px;
    height: 55px;
    object-fit: cover;
  }
  .toggle-button {
    font-size: 22px;
  }
  .alert-message{
    left: 33%;
    font-size: 0.7rem;
  }
  .mega{
    height: 18px;
    width: 18px;
  }
}

@media screen and (min-width: 375px) and (max-width: 450px) {
    .sidenav-open {
      width: 270px; 
      height: 100%;/* Set the width when the sidenav is open */
    }
    .sidenav-close {
        width: 270px; 
        height: 100%;/* Set the width when the sidenav is open */
      }
    .icon-nav {
      margin-left: 15%;
      margin-top: 13%;
      display: flex;
      gap: 38px;
    }
    .controls {
      margin-left: 6%;
      margin-top: 21%;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
    .controls a {
      font-size: 22px;
    }
    .med {
      height: 16px;
      width: 16px;
    }
    .seperator {
      display: flex;
      justify-content: space-between;
      padding: 32px 14px 20px 14px;
    }
    .logo {
      width: 80px;
      height: 49px;
      object-fit: contain;
    }
    .toggle-button {
      font-size: 23px;
    }
    .alert-message{
      left: 30%;
      font-size: 0.5rem;
      display: none;
    }
    .mega{
      height: 15px;
      width: 15px;
     
    }
    .top-number{
      font-size: 9px;
      font-weight: 500;
     position: absolute;
     right: 7%;
     top: 3%;
     color: rgb(77, 77, 77);
     font-family:"Varela Round", sans-serif;
    }
    
    
  }
  @media screen and (min-width: 320px) and (max-width: 375px){
    .sidenav-open {
      width: 240px;
      height: 100%; /* Set the width when the sidenav is open */
    }
    .sidenav-close {
        width: 240px;
        height: 100%; /* Set the width when the sidenav is open */
      }
    .icon-nav {
      margin-left: 15%;
      margin-top: 13%;
      display: flex;
      gap: 38px;
    }
    .controls {
      margin-left: 6%;
      margin-top: 21%;
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
    .controls a {
      font-size: 22px;
    }
    .med {
      height: 16px;
      width: 16px;
    }
    .seperator {
      display: flex;
      justify-content: space-between;
      padding: 30px 8px 20px 8px;
    }
    .logo {
      width: 70px;
      height: 48px;
      
    }
    .toggle-button {
      font-size: 21px;
    }
    .alert-message{
      left: 30%;
      font-size: 0.5rem;
      display: none;
    }
    .top-number{
      font-size: 9px;
      font-weight: 500;
     position: absolute;
     right: 6%;
     top: 3%;
     color: rgb(77, 77, 77);
     font-family:"Varela Round", sans-serif;
    }
    
    
  }
  @media screen and (max-width: 320px) {
    .seperator {
      display: flex;
      justify-content: space-between;
      padding: 28px 14px 24px 14px;
    }
    .logo {
      width: 70px;
      height: 45px;
      
    }
    .alert-message{
      left: 30%;
      font-size: 0.5rem;
      display: none;
    }
    
    
  }
