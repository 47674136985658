@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');


.send-button {
    font-family: "Syne", sans-serif;
    font-size: 15px;
    background: royalblue;
    color: white;
    padding: 0.5em 0.8em; 
    display: flex;
    align-items: center;
    border: none;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
}
  
.send-button span {
    display: block;
    color: white;
    font-weight: 500;
    margin-left: 0.3em;
    transition: transform 0.3s ease-in-out;
}
  
.send-button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
}
  
.send-button:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
}
  

.send-button:active {
    transform: scale(0.95);
}
  
@keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }
  
    to {
      transform: translateY(-0.1em);
    }
}

.send-button-link {
    text-decoration: none;
}



@media screen and (min-width: 425px) and (max-width: 768px){
    .send-button {
        font-size: 12px;
        padding: 0.2em 0.6em;   
    }
    
}

@media screen and  (max-width: 425px){
    .send-button {
        font-size: 10px;
        padding: 0.5em 0.6em;   
    }
    .send-button svg{
        width: 15px;
        height: 15px;
    }

}