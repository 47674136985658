/* Keyframes for bounce effect */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  
  .up {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60px;
    width: 60px;
  }
  
  .main-up {
    position: fixed;
    left: 96%;
    bottom: 52%;
    z-index: 1;
    display: none; /* Initially hidden */
  }
  
  .main-up.visible {
    display: flex; /* Show when the 'visible' class is added */
  }
  
  .link {
    text-decoration: none;
  }
  
  .span-up {
    font-size: 10.5px;
    color: rgb(0, 0, 0);
  }
  
  .up-arrow {
    transform: rotate(-90deg);
    width: 27px;
    height: 27px;
  }
  
  /* Apply bounce effect to WhatsApp icon */
  .wp-icon {
    position: fixed;
    left: 92%;
    bottom: 5%;
    height: 60px;
    width: 60px;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    animation: bounce 2s infinite;
  }
  
  /* Optional: Change icon on hover */
  .wp-icon:hover {
    animation: none;
    transform: scale(1.1); /* Scale the icon slightly on hover */
  }
  
  @media screen and (min-width: 320px) and (max-width: 375px) {
    .wp-icon {
      left: 83.5%;
      bottom: 3%;
      height: 48px;
      width: 48px;
    }
    .main-up {
      position: fixed;
      left: 89%;
      bottom: 52%;
      z-index: 1;
      display: none; /* Initially hidden */
    }
    .span-up {
      font-size: 8px;
      color: rgb(0, 0, 0);
    }
    
    .up img{
      width: 19px;
      height: 19px;
    }
  }
  
  @media screen and (min-width: 375px) and (max-width: 440px) {
    .wp-icon {
      left: 85%;
      bottom: 3%;
      height: 50px;
      width: 50px;
    }
    .main-up {
      position: fixed;
      left: 91%;
      bottom: 52%;
      z-index: 1;
      display: none; /* Initially hidden */
    }
    .span-up {
      font-size: 8px;
      color: rgb(0, 0, 0);
    }
    
    .up img{
      width: 19px;
      height: 19px;
    }
  }
  
  @media screen and (min-width: 440px) and (max-width: 768px) {
    .wp-icon {
      left: 89%;
      bottom: 5%;
      height: 48px;
      width: 48px;
    }
    .main-up {
      position: fixed;
      left: 94%;
      bottom: 52%;
      z-index: 1;
      display: none; /* Initially hidden */
    }
    .span-up {
      font-size: 8px;
      color: rgb(0, 0, 0);
    }
    
    .up img{
      width: 19px;
      height: 19px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1270px) {
    .wp-icon {
      left: 92%;
      bottom: 5%;
      height: 55px;
      width: 55px;
    }
    .main-up {
      position: fixed;
      left: 95%;
      bottom: 52%;
      z-index: 1;
      display: none; /* Initially hidden */
    }
    .span-up {
      font-size: 10px;
      color: rgb(0, 0, 0);
    }
    
    .up img{
      width: 25px;
      height: 25px;
    }
  }
  