@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@font-face {
  font-family: ragam;
  /* src: url(../Fonts/ragam-ragam-medium-500.otf); */
  src: url(../Fonts/KumbhSans-ExtraLight.ttf);
  /* src: url(../Fonts/Sweet\ Talk.otf); */
}
@font-face {
  font-family: subhead;
  src: url(../Fonts/VarelaRound-Regular.otf);
}

.Ab-img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.section2 {
  margin-top: 80px;
}
.Founder {
  height: 500px;
  object-fit: contain;
  width: 100%;
}
.about-container {
  padding: 0 50px;
  overflow: hidden; /* Add this to hide overflow */
}
.head h1 {
  font-family: "Varela Round", sans-serif;
  font-size: 45px;
}
.found-box h1{
  font-family: "Varela Round", sans-serif;
  font-size: 45px;
}
.para {
  font-size: 18.5px;
  text-align: justify;
  margin-left: 0;
}
.melam {
  height: 180px;
}
.mel {
  position: absolute;
  left: 80%;
  bottom: 0%;
}
.found-box {
  position: relative;
}
.ff{
  padding: 0;
}
.sub-head-ab{
  font-family: "Varela Round", sans-serif;
  font-size: 45px;
}
.found-flex{
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1700px) {
  .about-container{
    max-width: 1500px;
  }
  
}
@media screen and (max-width: 1360px) {
  .para {
    font-size: 14px;
    margin-top: 80px;
    
  }
}
@media screen and (min-width: 820px) and (max-width: 1349px) {
  .Ab-img {
    height: 380px;
    width: 100%;
  }
  .Founder {
    height: 400px;
  }
  .para {
    font-size: 14px;
    margin-top: 15px;
  }
  .found-box h1{
    font-family: "Varela Round", sans-serif;
    font-size: 45px;
  }
  .melam {
    height: 150px;
  }
  h1, .sub-head-ab {
    font-size: 40px;
    font-family: "Varela Round", sans-serif;
  }
  .head h1 {
    font-family: "Varela Round", sans-serif;
    font-size: 40px;
  }
}
@media screen and (min-width: 425px) and (max-width: 820px) {
  .Ab-img {
    height: 350px;
    width: 100%;
  }
  .head h1, .sub-head-ab {
    font-family: "Varela Round", sans-serif;
    font-size: 32px;
  }
  .melam {
    height: 120px;
  }
  .about-container {
    padding: 0 35px;
    overflow: hidden; /* Add this to hide overflow */
  }
  .Founder {
    height: 310px;
  }
  .map {
    width: 100%;
    height: 300px;
  }
  .mel {
    position: absolute;
    left: 75%;
    bottom: 0%;
  }
  .para {
    font-size: 14px;
    margin-top: 5px;
  }
  .line{
    display: none;
  }
  .found-box h1{
    font-family: "Varela Round", sans-serif;
    font-size: 32px;
  }
  
}
@media screen and (min-width: 375px) and (max-width: 438px) {
  .Ab-img {
    height: 250px;
    width: 100%;
  }
  .found-box h1{
    font-family: "Varela Round", sans-serif;
    font-size: 32px;
  }
  .head h1 {
    font-family: "Varela Round", sans-serif;
    font-size: 32px;
  }
  .Founder {
    height: 280px;
    object-fit: contain;
    
  }
  h1, .sub-head-ab {
    font-size: 32px;
    font-family: "Varela Round", sans-serif;
  }
  .found-flex {
    display: flex;
    align-items: none;
  }
  .about-container {
    padding: 0px 20px;
    overflow: hidden; 
  }
  .para {
    font-size: 14px;
    margin-top: 16px;
  }
  .melam {
    height: 90px;
    display: none;
  }
  .mel {
    position: absolute;
    left: 60%;
    bottom: 0%;
  }
  .sub-sec2{
    margin-top: 15px;
  }
  .line{
    display: none;
  }
  .map {
    width: 100%;
    height: 300px;
  }
  .section2 {
    margin-top: 50px;
  }
}
@media screen and (min-width: 320px) and (max-width: 375px) {
    .Ab-img {
      height: 240px;
      width: 100%;
      margin-top: 0;
    }
    .Founder {
      height: 250px;
      
    }
    .head h1{
      font-size: 25px;
    }
    h1, .sub-head-ab {
      font-size: 25px;
      font-family: "Varela Round", sans-serif;
    }
    .found-box h1{
      font-family: "Varela Round", sans-serif;
      font-size: 25px;
    }
    .sub-sec2{
      margin-top: 8px;
    }
    .found-flex {
      display: flex;
      align-items: none;
    }
    .about-container {
      padding: 0 20px;
      overflow: hidden; 
    }
    .para {
      font-size: 14px;
      margin-top: 16px;
    }
    .melam {
      height: 90px;
      display: none;
    }
    .mel {
      position: absolute;
      left: 60%;
      bottom: 0%;
    }
    .line{
      display: none;
    }
    .map {
      width: 100%;
      height: 300px;
    }
    .section2 {
      margin-top: 40px;
    }
  }