.flogo{
    width: 120px;
    height: 65px;
   
}
.soc{
    display: flex;
    gap: 20px;

}
.bdy{
    padding-top: -10px;
    
}
.ficon{
    width: 15px;
    height: 15px;
    margin-bottom: 3.5%;
   
}
.flex2{
    display: flex;
    gap: 10px;
    align-items: center;
    
}

.fotter{
    margin-top: 70px;
    background: #f3f3f3;
    padding: 0 40px;
    
}
.sicon{
    width: 20px;
    height: 20px;
}
.fot-p{
    font-family: Arial, Helvetica, sans-serif;
    color: #6A6A6A;
    font-size: 14px;
    
}
/* @media screen and (min-width: 1700px) and (max-width: 2560px){
    .sicon{
        width: 28px;
        height: 28px;
    }
    .fot-p{
        font-size: 20px;
      
    }
    .bdy{
        padding-top: 60px;
    }
    .flogo{
        width: 150px;
        height: 100px;
        object-fit: cover;
       
    }
    .fotter{
        padding: 0 70px 20px 70px ;
        margin-top: 80px;
        
    }
} */
@media screen and (min-width: 1700px) {
    .bd2{
      max-width: 1500px;
    }
    
    
  }
@media screen and (min-width: 1025px) and (max-width: 1270px){
    .mp{
        margin-bottom: 15px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1025px){
    .fot-p{
        font-size: 14px;
    }
    .ficon{
        margin-bottom: 6%;
    }
    .sicon{
        width: 19px;
        height: 19px;
    }
    .bdy{
        padding: 8px 20px;
    }
    .fotter{
        padding: 10px 13px 20px 13px ;
        margin-top: 80px;
        
    }

}
@media screen and (min-width: 440px) and (max-width: 768px){
    .fot-p{
        font-size: 12px;
    }
    .ficon{
        margin-bottom: 4%;
    }
    .sicon{
        width: 20px;
        height: 20px;
    }
    .fotter{
        padding: 10px 12px 20px 12px ;
        margin-top: 80px;
        
    }
    .ficon{
        width: 12px;
        height: 12px;
        margin-bottom: 8%;

    }
    .bdy{
        padding: 0px 20px;
    }
    
}
@media screen and (min-width: 376px) and (max-width: 440px){
    .fot-p{
        font-size: 12px;
    }
    .bdy{
        padding: 10px 5px;
    }
    .ficon{
        margin-bottom: 4%;
    }
    .sicon{
        width: 17px;
        height: 17px;
    }
    .soc{
        margin-top: 20px;
    }
    .map{
        margin-top: 25px;
    }
    input{
        padding: 8px;
    }
    input::placeholder {
        font-size: 14.5px; /* Adjust the size as needed */
        color: #888; /* Optional: Change the placeholder text color */
        text-indent: 4%;
    }
    .fotter{
        padding: 10px 12px 20px 12px ;
        margin-top: 80px;
        
    }
    .flogo{
        width: 90px;
        height: 45px;
        object-fit: cover;
       
    }
    .ficon {
        width: 12px;
        height: 12px;}
}
@media screen and (min-width: 320px) and (max-width: 376px){
    .fot-p{
        font-size: 12.5px;
    }
    .bdy{
        padding: 10px 5px;
    }
    .ficon{
        margin-bottom: 7%;
    }
    .sicon{
        width: 15px;
        height: 15px;
    }
    .soc{
        margin-top: 20px;
    }
    .map{
        margin-top: 25px;
    }
    input{
        padding: 8px;
    }
    input::placeholder {
        font-size: 14.5px; /* Adjust the size as needed */
        color: #888; /* Optional: Change the placeholder text color */
        text-indent: 4%;
    }
    .fotter{
        padding: 10px 12px 20px 12px ;
        margin-top: 80px;
        
    }
    .flogo{
        width: 90px;
        height: 50px;
        object-fit: cover;
       
    }
}