.accordion {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
  }
  
  .faq-question {
    padding: 15px;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  
  .faq-question:hover {
    background-color: #f1f1f1;
  }
  
  .faq-answer {
    overflow: hidden;
    will-change: height, opacity;
  }
  
  .faq-answer .content {
    padding: 15px;
    background-color: #fff;
    color: #333;
  }
  