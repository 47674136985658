@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400..700;1,400..700&display=swap');
*{
  margin: 0;
  padding: 0;
}
.fullwidth-container{
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Kumbh Sans", sans-serif;
  /* src: url(../Fonts/subhead-subhead-medium-500.otf); */
  src: url(../Fonts/KumbhSans-ExtraLight.ttf);
  /* src: url(../Fonts/Sweet\ Talk.otf); */
}

/* @font-face {
  font-family: "Varela Round", sans-serif;
  src: url(../Fonts/VarelaRound-Regular.otf);
} */
@font-face {
  font-family:parafont;
  src: url(../Fonts/AlegreyaSans-Medium.ttf);
}

.outer {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  /* Full screen height */
  background-color: #ffffff; /* To make sure we cover the full background */
}
.fl a{
text-decoration: none;
color: black;
}
.carousel {
  height: 700px;
  width: 100%;
  object-fit: cover;
  
}
.blue-moon p{
  font-size: 14px;
}
.heading h1 {
  font-family: "Kumbh Sans", sans-serif;
  color: #282828;
  font-size: 50px;
  font-weight: 200;
}
.head-p p{
  margin-top: 38px;
  color: #8d8d8d;
  font-size: 13px;
}
/* Head container */
.head-container{
  padding: 10px 60px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}
/* General container */
.ab-container {
  padding: 20px 60px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

/* Thumbnail view */
.thumbnail-container {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover to maintain aspect ratio */
  padding: 10px 10px;
}
.one{
  width: 100%;
}

/* Play button */
.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
}
.blurred {
  filter: blur(5px); /* Example blur effect */
}
.m-cont {
  position: relative;
  width: 80px;
  height: 80px;
}

.circle {
  width: 100%;
  height: 100%;
  animation: rotate 10s linear infinite;
  transform-origin: center;
}

text {
  font-size: 25px; /* Adjust font size if needed */
  fill: #ffffff;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrow img {
  width: 45px;
  height: 45px;
}

/* Keyframes for rotating animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.happyimg{
  width: 100%;
  height: 300px;
  object-fit: cover;
}
/* Video player view */
.video-container {
  position: relative;
  width: 100%;
  height: 100%; /* Adjusted to 100% */
  padding: 10px 10px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover to maintain aspect ratio */
  padding: 10px 0px;
}

.back-btn {
  position: absolute;
  top: 60px;
  left: 40px;
  background: #ec1400;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.back-btn:hover {
  background: #a50d00;
}
.ring {
  width: 120px;
  height: 110px;
  
}

/* section 3 */
.section-2 {
  margin-top: 100px;
}
.icons img {
  width: 50px;
  height: 50px;
}
.icons {
  display: flex;
  justify-content: space-evenly;
  gap: 120px;
}
.ic-items h6{
  font-size: 12px;
  font-family: "Syne", sans-serif;

}
.ic-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.vis {
  display: none;
}
.god {
  width: 100%;
  height: 490px;
  margin-top: 55px;
  object-fit: cover;
}
.theer {
  width: 48%;
  height: 160px;
  margin-left: 48%;
}
.arrow-1 {
  width: 9%;
  height: 14px;
  margin-left: 8px;
  transition: 0.5s;
  cursor: pointer;
  display: none   ;
}
.hidd {
  display: none;
}
.ab-p {
  font-size: 18px;
  color: #616161;
  font-family: "Varela Round", sans-serif;
}
.ab-h {
  font-size: 22px;
  font-weight: 300;
  font-family: "Varela Round", sans-serif;
}
.gallery-button{
  font-family: "Varela Round", sans-serif;  
  font-size: 20px;

}
.gallery-arrow{
  margin-top: 8px;
}
.sub-head {
  font-family: "Varela Round", sans-serif;
  font-size: 45px;
  font-weight: 100;
}
.flexu {
  display: flex;
  align-items: center;
  
}
span {
  color: #6d1414;
  cursor: pointer;
}
.see-more {
  font-size: 19px;
}
.arrow-1 {
  width: 8%;
  height: 10px;
  margin-left: 8px;
  transition: 0.5s;
  cursor: pointer;
}


.play-pause-icon{
  position: absolute;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 50px;
  color: #fff;
 
}
.pause-icon{
  position: absolute;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 50px;
  color: #fff;
 
}




/* accordian  */

.accordion {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.faq-item {
  border-bottom: 1px solid #ddd;
}

.faq-question {
  padding: 15px;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #f1f1f1;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
  padding: 0 15px;
  background-color: #fff;
  color: #333;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.3s ease;
}

.faq-answer.active {
  max-height: 1000px; /* Ensure this is large enough to fit the content */
  padding: 15px;
  opacity: 1;
}


/* section4 */

.wd {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.wd-1{
  width: 100%;
  height: 350px;
  object-fit: cover;
  transform: scale(1.1);
  transition: transform 0.5s ease; 
}
.image-container {
  overflow: hidden; /* Ensures the image does not overflow its container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a subtle shadow */
  transition: transform 0.5s ease; /* Smooth transition effect */
}
.image-container:hover .wd-1 {
  transform: scale(1); /* Zoom effect on hover */
}
.wd h4 {
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.fl {
  display: flex;
  align-items: center;
  gap: 8px;
}
.fl img {
  width: 5.8%;
  height: 8px;
  margin-bottom: 5px;
}
.youtube-head, .wedding-gallery-head{
      font-family: "Varela Round", sans-serif;
      font-size: 45px;
      margin-bottom: 40px;
}

/* Reels Heading */

.reel-row{
  display: flex;
  justify-content: start;
  margin-left: 100px;
}



p{
  color: #616161;
  font-family: "Syne", sans-serif;
  font-size: 18px;
}
.section-4 {
  width: 100%;
  margin-top: 80px;
}
/* section5 */

input {
  border: solid rgb(201, 201, 201) 1px;
  border-radius: 5px;
  padding: 12px;
}
.btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #ec1400;
  width: 100px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 12px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.btn:hover {
  opacity: 0.95;
  color: white;
}

.btn .animation {
  border-radius: 100%;
  animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1),
      0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
      0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1),
      0 0 0 80px rgba(255, 255, 255, 0);
  }
}

button {
  position: relative;
  display: inline-block;
  padding: 5px 24px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #616161;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #616161;
  border-radius: 8px;
  box-shadow: inset 0 0 0 0 #616161;
}

button:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #616161;
}

button:active {
  transform: scale(0.9);
}
.flex-1 {
  display: flex;
  flex-direction: column;
}

.section-5 {
  width: 100%;
  margin-top: 80px;
}
.line {
  background: #a7a7a7;
  width: 2px;
  height: 350px;
  margin-left: 50%;
}
.map {
  border: solid #919191 5px;
}
.gallery-row{
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
}
.youtube-iframe1, .youtube-iframe2{
  width: 100%;
  height: 300px;
}
.youtube-iframe3{
  position: relative;
  width: 70%;
  height:auto;
  object-fit: cover;
  border-radius: 20px;
}



@media screen and (min-width: 1700px) {
  .ab-container{
    max-width: 1500px;
  }
  .head-container{
    max-width: 1500px;
  }
  .main-slider{
    max-width: 1500px;
  }
  
  
}
@media screen and (min-width: 1350px) and (max-width: 1370px){
  .ab-p{
    font-size: 17px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1270px){
  .heading h1{
    font-size: 40px;
  }
  .ring {
    
    display: none;
  }
  .sub-head, .youtube-head, .wedding-gallery-head{
    font-size: 40px;
  }
  .moon-para{
    font-size: 16px;
  }
  .ab-p{
    font-size: 16.5px;
  }
}
@media screen and (min-width: 820px) and (max-width: 1025px) {
  .hide {
    display: none;
  }
  .ab-h {
    font-size: 15px;
  }
  .heading h1 {
    font-size: 40px;
  }
  .youtube-head, .wedding-gallery-head{
    font-size: 35px;
  }
  .gallery-button{
    font-size: 14px;
  }
  .ring {
    width: 100px;
    height: 90px;
  }
  .head-container{
    padding: 5px 40px;
  }
  .ab-container {
    padding: 20px 40px;
  }
  .circle {
    width: 85%;
    height: 85%;
    margin-left: 7%;
    margin-top: 7%;
    animation: rotate 10s linear infinite;
    transform-origin: center;
  }

  text {
    font-size: 25px; /* Adjust font size if needed */
    fill: #ffffff;
  }
  .arrow img {
    width: 40px;
    height: 40px;
  }
  .icons img {
    width: 40px;
    height: 40px;
  }
  .icons {
    gap: 80px;
  }
  .ic-items h6 {
    font-size: 11px;
  }
  .god {
    width: 100%;
    height: 440px;
    margin-top: 40px;
  }
  .theer {
    width: 52%;
    height: 120px;
    margin-left: 48%;
  }
  .ab-p {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .see-more {
    font-size: 16px;
    margin-top: 0;
  }
  
  .blue-moon p{
    font-size: 13px;
  }
  .wd-1{
    width: 100%;
    height: 210px;
  }
  .wd h4 {
    font-size: 15px;
    
  }
  .fl img {
    width: 5%;
    height: 5.5 px;
    margin-bottom: 5px;
  }
  input {
    
    padding: 10px;
  }
  input::placeholder {
    font-size: 12px; 
    text-indent: 5%;
}
.map {
  width: 100%;
  height: 300px;
}
.sub-head{
  font-size:35px ;
}
.youtube-iframe1, .youtube-iframe2{
  height: 240px;
}
.btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #ec1400;
  width: 80px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 9px 20px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}
}

/* for laptop 1024 */


@media screen and (min-width: 436px) and (max-width: 820px) {
  .hide {
    display: none;
  }
  .ab-h {
    font-size: 16px;
    margin-top: 8px;
  }
  .heading h1 {
    font-size: 30px;
  }
  .ring {
    width: 90px;
    height: 80px;
    /* margin-top: 22px; */
  }
  .head-container{
    padding: 5px 35px;
  }
  .ab-container {
    padding: 20px 35px;
  }
  .circle {
    width: 73%;
    height: 73%;
    margin-left: 13%;
    margin-top:13%;
    animation: rotate 10s linear infinite;
    transform-origin: center;
  }

  text {
    font-size: 25px; /* Adjust font size if needed */
    fill: #ffffff;
  }
  .arrow img {
    width: 35px;
    height: 35px;
  }
  .icons img {
    width: 35px;
    height: 35px;
  }
  .icons {
    gap: 30px;
  }
  .ic-items h6 {
    font-size: 10px;
  }
  .god {
    width: 100%;
    height: 320px;
    margin-top: 10px;
    object-fit: cover;
  }
  .theer {
    width: 58%;
    height: 105px;
    margin-left: 120%;
  }
  .ab-p {
    font-size: 12.5px;
    margin-bottom: 5px;
  }
  .hidd{
    display: none;
  }
  .see-more {
    font-size: 14px;
    margin-top: 0;
  }
  
  .blue-moon p{
    font-size: 12px;
  }
  .wd-1{
    width: 100%;
    height: 170px;
  }
  .wd h4 {
    font-size: 13px;
    
  }
  .fl img {
    width: 5.5%;
    height: 3.5px;
    margin-bottom: 5px;
  }
  input {
    padding: 7px;
  }
  input::placeholder {
    font-size: 12px; 
    text-indent: 5%;
}
.map {
  width: 100%;
  height: 300px;
}
.ic-items{
  gap: 7px;
}
.img-mt{
  margin-top: 20px;
}
.hidu{
  display: none;
}
.sub-head {
  font-family: "Varela Round", sans-serif;
  font-size: 32px;
}
.section-4{
  margin-top: 35px;
}
.section-2{
  margin-top: 40px;
}
.carousel{
  height: 500px;
}
.btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #ec1400;
  width: 70px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 8px 20px;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}
.happyimg{
  width: 100%;
  height: auto;
}

.wedding-gallery-head, .youtube-head{
  font-size: 32px;
}

}


@media screen and (min-width: 406px) and (max-width: 440px) {
  .hide {
    display: none;
  }
  .one{
    width: 100%;
  }
  .ab-h {
    font-size: 15px;
    margin-top: 8px;
  }
  .heading h1 {
    font-size: 28px;
  }
  .youtube-head{
    font-size: 32px;
  }
  .wedding-gallery-head{
    font-size: 32px;
  }
  .gallery-button{
    font-size: 14px;
  }
  .ring {
    width: 70px;
    height: 60px;
    /* margin-top: 16px; */
  }
  .head-container{
    padding: 5px 20px;
  }
  .ab-container {
    padding: 15px 20px;
  }
  .circle {
    width: 55%;
    height: 55%;
    margin-left: 22%;
    margin-top:23%;
    animation: rotate 10s linear infinite;
    transform-origin: center;
  }

  text {
    font-size: 25px; /* Adjust font size if needed */
    fill: #ffffff;
  }
  .arrow img {
    width: 25px;
    height: 25px;
  }
  .icons img {
    width: 30px;
    height: 30px;
  }
  .icons {
    gap: 5px;
    padding: 0;
  }
  
  .ic-items h6 {
    font-size: 8px;
  }
  .god {
    width: 100%;
    height: 280px;
    margin-top: 17px;
  }
  .theer {
    width: 58%;
    height: 105px;
    margin-left: 100%;
  }
  .ab-p {
    font-size: 13px;
    margin-bottom: 5px;
    color: #919191;
    
  }
  .just{
    text-align: justify;
  }
  .see-more {
    font-size: 15px;
    margin-top: 10px;
    font-weight: 500;
  }
  
  .blue-moon p{
    font-size: 12px;
  }
  .wd-1{
    width: 100%;
    height: 170px;
  }
  .wd h4 {
    font-size: 14px;
    
  }
  .fl img {
    width: 5.5%;
    height: 5.5px;
    margin-bottom: 5px;
  }
  input {
    padding: 6px;

  }
  input::placeholder {
    font-size: 5px; 
    text-indent: 2.5%;
}
.map {
  width: 100%;
  height: 300px;
}
.ic-items{
  gap: 8px;
}
.hidd{
  display: block;
}
.hidu{
  display: none;
}
.hid{
  display: none;
}
.btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #ec1400;
  width: 80px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 8px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.sub-head {
  font-family: "Varela Round", sans-serif;
  font-size: 32px;
}
.section-4{
  margin-top: 35px;
  padding: 0px ;
}
.section-2{
  margin-top: 40px;
}
.hidd{
  display: none;
}
.line{
  display: none;
}
.thumbnail, .video-container{
  padding-top:0px ;
}
.thumbnail-container {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}

.thumbnail {
  width: 100%;
  height:  380px;
  object-fit:cover ; /* Cover to maintain aspect ratio */
  object-position: left center;
  padding: 10px 10px;
}
.video-container {
  position: relative;
  width: 100%;
  height: 420px; /* Adjusted to 100% */
  padding: 10px 10px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}

.video-container video {
  width: 100%;
  height: 420px;
  object-fit: cover; /* Cover to maintain aspect ratio */
  padding: 10px 0px;
}
.carousel{
  height: 500px;
}
.arrow-1 {
  width: 9%;
  height: 6px;
  margin-left: 8px;
  transition: 0.5s;
  cursor: pointer;
  display: none;
}
.back-btn {
  position: absolute;
  top: 43px;
  left: 30px;
  background: #ec1400;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background 0.3s ease;
  font-size: 12px;
}
.youtube-iframe1, .youtube-iframe2{
  width: 100%;
  height: 180px;
}
.happyimg{
  margin-top: 10px;
}
.carousel{
  height: 500px;
}
.wedding-gallery-head{
  margin-bottom: 32px;
}
.play-pause-icon{
  top: 50%;
  left: 32%;
  font-size: 30px;
 
}
.pause-icon{
  top: 50%;
  left: 32%;
  
  font-size: 30px;
 
}
.reel-row{
  display: flex;
  justify-content: start;
  margin-left: 10px;
}
.youtube-iframe3{
  width: 60%;
  height:auto;
  object-fit: cover;
  border-radius: 10px;
}
}
@media screen and (min-width: 376px) and (max-width: 406px){
  .hide {
    display: none;
  }
  .one{
    width: 100%;
  }
  .ab-h {
    font-size: 18px;
    margin-top: 8px;
  }
  .heading h1 {
    font-size: 25px;
  }
  .ring {
    width: 80px;
    height: 70px;
    /* margin-top: 15px; */
  }
  .head-container{
    padding: 5px 20px;
  }
  .ab-container {

      padding: 15px 20px;
    
  }
  .circle {
    width: 55%;
    height: 55%;
    margin-left: 22%;
    margin-top:23%;
    animation: rotate 10s linear infinite;
    transform-origin: center;
  }

  text {
    font-size: 25px; /* Adjust font size if needed */
    fill: #ffffff;
  }
  .arrow img {
    width: 25px;
    height: 25px;
  }
  .icons img {
    width: 30px;
    height: 30px;
  }
  .icons {
    gap: 5px;
    padding: 0;
  }
  .ic-items h6 {
    font-size: 8px;
  }
  .god {
    width: 100%;
    height: 280px;
    margin-top: 17px;
  }
  .theer {
    width: 58%;
    height: 105px;
    margin-left: 100%;
  }
  .ab-p {
    font-size: 14px;
    margin-bottom: 5px;
    color: #919191;
    
  }
  .just{
    text-align: justify;
  }
  .see-more {
    font-size: 15px;
    margin-top: 10px;
    font-weight: 500;
  }
  
  .blue-moon p{
    font-size: 12px;
  }
  .wd-1{
    width: 100%;
    height: 170px;
  }
  .wd h4 {
    font-size: 14px;
    
  }
  .fl img {
    width: 5.5%;
    height: 5.5px;
    margin-bottom: 5px;
  }
  input {
    padding: 6px;

  }
  input::placeholder {
    font-size: 12px; 
    text-indent: 0;
}
.map {
  width: 100%;
  height: 300px;
}
.ic-items{
  gap: 8px;
}
.hidd{
  display: block;
}
.hidu{
  display: none;
}
.hid{
  display: none;
}
.btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #ec1400;
  width: 80px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 8px 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}

.sub-head {
  font-family: "Varela Round", sans-serif;
  font-size: 25px;
}
.section-4{
  margin-top: 35px;
  padding: 0px ;
}
.section-2{
  margin-top: 40px;
}
.hidd{
  display: none;
}
.line{
  display: none;
}
.thumbnail, .video-container{
  padding-top:0px ;
}
.thumbnail-container {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}

.thumbnail {
  width: 100%;
  height: 420px;
  object-fit:cover ; /* Cover to maintain aspect ratio */
  object-position: left center;
  padding: 10px 10px;
}
.video-container {
  position: relative;
  width: 100%;
  height: 420px; /* Adjusted to 100% */
  padding: 10px 10px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}

.video-container video {
  width: 100%;
  height: 420px;
  object-fit: cover; /* Cover to maintain aspect ratio */
  padding: 10px 0px;
}
.back-btn {
  position: absolute;
  top: 43px;
  left: 30px;
  background: #ec1400;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background 0.3s ease;
  font-size: 12px;
}
.arrow-1 {
  width: 9%;
  height: 6px;
  margin-left: 8px;
  transition: 0.5s;
  cursor: pointer;
  display: none;
}
.happyimg{
  margin-top: 10px;
}
.wedding-gallery-head,.youtube-head, .sub-head{
  font-size: 25px;
}
.wedding-gallery-head{
  margin-bottom: 25px;
}
.carousel{
  height: 500px;
}
.gallery-button{
  font-size: 14px;
}
.play-pause-icon{
  top: 50%;
  left: 32%;
  font-size: 30px;
 
}
.pause-icon{
  top: 50%;
  left: 32%;
  
  font-size: 30px;
 
}
.reel-row{
  display: flex;
  justify-content: start;
  margin-left: 10px;
}
.youtube-iframe3{
  width: 60%;
  height:auto;
  object-fit: cover;
  border-radius: 10px;
}
}
@media screen and (min-width: 319px) and (max-width: 376px) {
  .hide {
    display: none;
  }
  .arrow-1 {
    width: 9%;
    height: 6px;
    margin-left: 8px;
    transition: 0.5s;
    cursor: pointer;
    display: none;
  }
  .ab-h {
    font-size: 15px;
    margin-top: 8px;
  }
  .heading h1 {
    font-size: 22px;
  }
  .ring {
    width: 65px;
    height: 55px;
    margin-top: 0px;
  }
  .head-container{
    padding: 5px 20px;
  }
  .ab-container {
    padding: 15px 20px;
  }
  .circle {
    width: 55%;
    height: 55%;
    margin-left: 22%;
    margin-top:23%;
    animation: rotate 10s linear infinite;
    transform-origin: center;
  }

  text {
    font-size: 25px; /* Adjust font size if needed */
    fill: #ffffff;
  }
  .moon-para{
    font-size: 18px;
  }
  .read-more-button{
    font-size: 13px;
  }
  .wedding-gallery-head{
    font-size: 25px;
    margin-bottom: 20px;
  }
    .arrow img {
    width: 25px;
    height: 25px;
  }
  .icons img {
    width: 28px;
    height: 28px;
  }
  .icons {
    gap: 5px;
    padding: 0;
  }
  .ic-items h6 {
    font-size: 6px;
  }
  .god {
    width: 100%;
    height: 250px;
    margin-top: 17px;
  }
  .theer {
    width: 58%;
    height: 105px;
    margin-left: 100%;
  }
  .ab-p {
    font-size: 13px;
    margin-bottom: 5px;
    color: #919191;
    
  }
  .just{
    text-align: justify;
  }
  .see-more {
    font-size: 13px;
    margin-top: 10px;
    font-weight: 500;
  }
  .youtube-head{
    font-size: 25px;
  }
  .blue-moon p{
    font-size: 12px;
    color: #919191;
  }
  .wd-1{
    width: 100%;
    height: 165px;
  }
  .wd h4 {
    font-size: 12px;
    
  }
  .fl img {
    width: 5.5%;
    height: 5.5px;
    margin-bottom: 5px;
  }
  input {
    padding: 6px;

  }
  input::placeholder {
    font-size: 12px; 
    text-indent: 2%;
}
.map {
  width: 100%;
  height: 300px;
}
.ic-items{
  gap: 5px;
}
.hidd{
  display: block;
}
.hidu{
  display: none;
}
.hid{
  display: none;
}

.sub-head {
  font-family: "Varela Round", sans-serif;
  font-size: 25px;
}
.section-4{
  margin-top: 35px;
  padding: 0px ;
}
.section-2{
  margin-top: 40px;
}
.hidd{
  display: none;
}
.line{
  display: none;
}
.thumbnail, .video-container{
  padding-top:0px ;
}
.thumbnail-container {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}
.ring{
  /* margin-top: 11px; */
}
.carousel{
  height: 425px;
}

.thumbnail {
  width: 100%;
  height: 420px;
  object-fit:cover ; /* Cover to maintain aspect ratio */
  object-position: left center;
  padding: 10px 10px;
}
.video-container {
  position: relative;
  width: 100%;
  height: 420px; /* Adjusted to 100% */
  padding: 10px 10px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: opacity 0.5s ease;
}
.youtube-iframe1{
  width: 300px;
  height: 200px;
  
  
}
.youtube-iframe2{
  width: 300px;
  height: 200px;
  padding: 0;

}
.gallery-row{
  /* border: 1px solid black; */
}
.gallery-col-home{
  margin-top: 10px;
}

.video-container video {
  width: 100%;
  height: 420px;
  object-fit: cover; /* Cover to maintain aspect ratio */
  padding: 10px 0px;
}

.btn {
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: #ec1400;
  width: 70px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 8px 20px;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  overflow: hidden;
  cursor: pointer;
}
.section-5{
  margin-top: 40px;
}
.gallery-button {
  font-size: 14px;
}
.heading h1{
  font-size: 22px;
}

.play-pause-icon{
  top: 50%;
  left: 32%;
  font-size: 30px;
 
}
.pause-icon{
  top: 50%;
  left: 32%;
  
  font-size: 30px;
 
}
.reel-row{
  display: flex;
  justify-content: start;
  margin-left: 10px;
}
.youtube-iframe3{
  width: 60%;
  height:auto;
  object-fit: cover;
  border-radius: 10px;
}
}
/* ................................... 347px media query ..................................... */
@media screen and (max-width: 347px){
  .head-container{
    padding: 10px 20px;
  }
  .ab-container {
    padding: 20px 20px;
  }
  .ring {
    width: 60px;
    height: 50px;
    margin-top: 0px;
  }
  
  .thumbnail-container {
    position: relative;
    width: 100%;
    padding: 10px 0px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    transition: opacity 0.5s ease;
  }
  .thumbnail{
    height: 400px;
  }
  .gallery-row{
    margin-right: 290px;
  }
  .youtube-iframe1, .youtube-iframe2{
    width: 80%;
  }
  .play-pause-icon{
    top: 50%;
    left: 32%;
    font-size: 30px;
   
  }
  .pause-icon{
    top: 50%;
    left: 32%;
    
    font-size: 30px;
   
  }
  .reel-row{
    display: flex;
    justify-content: start;
    margin-left: 10px;
  }
  .youtube-iframe3{
    width: 60%;
    height:auto;
    object-fit: cover;
    border-radius: 10px;
  }
  
}
@media screen and (max-width: 370px){
  .ring{
    display: none;
  }
}
@media screen and (max-width: 320px){
  .head-container{
    padding: 0px 20px;
  }
  .ab-container {
    padding: 10px 20px;
  }
  .head h1{
    font-size: 25px;
  }
  .play-pause-icon{
    top: 50%;
    left: 32%;
    font-size: 30px;
   
  }
  .pause-icon{
    top: 50%;
    left: 32%;
    
    font-size: 30px;
   
  }
  .reel-row{
    display: flex;
    justify-content: start;
    margin-left: 10px;
  }
  .youtube-iframe3{
    width: 60%;
    height:auto;
    object-fit: cover;
    border-radius: 10px;
  }
}
