/* Gallery.css */

/* Ensures the container doesn't overflow its bounds */
.gal-container {
  overflow: hidden;
  position: relative; /* Ensures the container is the context for absolutely positioned elements */
  z-index: 1; /* Lower than menu */
  padding: 20px 60px;
}

.gal-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.gal-h2 {
  font-family: "Varela Round", sans-serif;
  font-size: 45px;
}

.gallery-p {
  font-family: "Syne", sans-serif;
  font-size: 13px;
}
.prv, .nxt{
  width: 30px;
  height: 30px;
}
.gal-column {
  flex: 25%;
  max-width: 66.6%;
  padding: 0 4px;
  position: relative; /* Establishes the positioning context for child elements */
  z-index: 2;
  background: #ffffff;
}

.gal-column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  height: auto; /* Ensures images keep their aspect ratio */
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s linear;
}

.flow {
  position: absolute;
  height: 150px;
  width: 150px;
  top: 8%; /* Adjust the position as needed */
  left: 36%; /* Adjust the position as needed */
  margin-top: 8px;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10; /* Ensures it is above other content */
}

@media screen and (min-width: 1700px) {
  .gal-container{
    max-width: 1500px;
  }
  
}

@media screen and (max-width: 700px) {
  .gal-column {
    flex: 100%;
    max-width: 50%;
  }
  .gal-h2 {
    font-family: "Varela Round", sans-serif;
    font-size: 32px;
  }
  .gal-container {
    overflow: hidden;
    position: relative; /* Ensures the container is the context for absolutely positioned elements */
    z-index: 1; /* Lower than menu */
    padding: 20px 35px;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .gal-column {
    flex: 100%;
    max-width: 100%;
  }
  .gal-h2 {
    font-family: "Varela Round", sans-serif;
    font-size: 30px;
  }
  .gallery-p {
    font-family: "Syne", sans-serif;
    font-size: 11px;
  }
  
}
@media screen and (max-width: 440px){
  .gal-h2 {
    font-family: "Varela Round", sans-serif;
    font-size: 28px;
  }
  .gal-container {

    padding: 0px 26px;
  }
}

/* Modal styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
  height: auto;
  width: auto;
  border-radius: 5px;
  cursor: pointer;
}

.gal-close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.gal-close:hover,
.gal-close:focus {
  
  text-decoration: none;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: #000000;
  font-weight: bold;
  font-size: 30px;
  transition: 0.3s;
  user-select: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}


