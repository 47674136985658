#app {
    height: 100%;
  }
  /* @font-face {
    font-family: ragam;
    src: url(../Fonts/ragam-ragam-medium-500.otf);
    src: url(../Fonts/KumbhSans-ExtraLight.ttf);
    src: url(../Fonts/Sweet\ Talk.otf);
  } */
  /* @font-face {
    font-family: subhead;
    src: url(../Fonts/VarelaRound-Regular.otf);
} */
  html,
  body {
    position: relative;
    height: 100%;
  }
  .testi-head{
    display: flex;
    align-items: center;
    gap: 3%;

  }
  .testi-head h5{
    font-size: 15px;
    font-weight: 400;
  }
  .test-cont{
    margin-top: 10%;
    
  }
  
  /* .testimonial-head{
    margin-top: 32px;
    font-family: "Varela Round", sans-serif;
    
  } */
  body {
   
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    
    font-size: 18px;
    
  
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    
    object-fit: cover;
  }
  
  .card{
    padding: 13px;
    max-width: 400px;
    max-height: 180px;
  }
 .card-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
 }
 .star{
    width: 10px;
    height: 10px;
    margin-top: 2.2%;
 }
 .star-flex{
    display: flex;
    gap: 3px;
 }
 .test-para{
    font-size: 12px;
    margin-top: 1.8%;

 }
 .col1-test h1{
  font-size: 40px;
 }
 .p-1{display: block;
font-size: 13px;}
 .p-2{
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 440px){
  .col1-test h1{
    margin-top: 20px;
    font-size: 27px;
  }
  .col1-test p{
    font-size: 8px;
  }
  
  .card{
    padding: 13px;
    max-width: 280px;
    max-height: 160px;
    margin-right: 24%;
  }
  .card-img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
 }
 .test-para{
  font-size: 8px;
  margin-top: 1.8%;

}
.testi-head h5{
  font-size: 9px;
 }
.star{
  width: 7px;
  height: 7px;
  margin-top: 3.8%;
}
}
 @media screen and (min-width: 440px) and (max-width: 768px){
  .card{
    padding: 13px;
    max-width: 200px;
    max-height: 160px;
  }
 .card-img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
 }
 .star{
    width: 8px;
    height: 8px;
    margin-top: 2.8%;
 }
 .star-flex{
    display: flex;
    gap: 3px;
 }
 .test-para{
    font-size: 9px;
    margin-top: 1.8%;

 }
 .testi-head h5{
  font-size: 11px;
 }
 .test-cont{
  margin-top: 5%;
  
}
.col1-test h1{
  margin-top: 30px;
}
.p-1{
  display: none;
}
.p-2{
  display: block;
}
.col1-test p{
  font-size: 9px;
}
 }
 @media screen and (min-width: 1025px) and (max-width: 1270px){
  .col1-test h1{
    margin-top: 30px;
    font-size: 40px;
  }
 }
 @media screen and (min-width: 768px) and (max-width: 1025px){
  .card{
    padding: 13px;
    max-width: 280px;
    max-height: 160px;
  }
 .card-img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
 }
 .star{
    width: 8px;
    height: 8px;
    margin-top: 2.2%;
 }
 .star-flex{
    display: flex;
    gap: 3px;
 }
 .test-para{
    font-size: 10px;
    margin-top: 1.8%;

 }
 .test-cont{
  margin-top: 5%;
  
}
.col1-test h1{
  margin-top: 30px;
  font-size: 40px;
}
.col1-test p{
  font-size: 10px;
}
.col1-test h1{
  font-size: 40px;
 }
 }
 @media screen and (min-width: 768px) and (max-width: 1025px){
  .col1-test h1{
    font-size: 40px;
   }
 }