@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Varela+Round&display=swap');
*{
    padding: 0;
    margin: 0;
}


@font-face {
    font-family: ragam;
    /* src: url(../Fonts/ragam-ragam-medium-500.otf); */
    src: url(../Fonts/KumbhSans-ExtraLight.ttf);
    /* src: url(../Fonts/Sweet\ Talk.otf); */
}
@font-face {
    font-family: subhead;
    src: url(../Fonts/VarelaRound-Regular.otf);
}
.main-container1{
    /* border: 1px solid black; */
    /* width: 80%; */
    padding: 0;
    margin: 0 0 0 0 auto;

}
.hall-main{
    margin-top: 130px;
}
.our-wedding{
    font-family: "Varela Round", sans-serif;
    font-size: 45px;
    /* text-align: start; */
    /* border: 1px solid red;; */
}
.kurutholai-content{
    align-self: start;
}

.Kurutholai-head{
    /* font-family: "Kumbh Sans",  sans-serif; */
    /* font-family: 'Dancing Script', cursive; */
    /* font-family: 'Great Vibes', cursive; */
    font-family: "Varela Round", sans-serif;
    font-weight: 300;
    font-size: 31px;
    text-align: start;
}
.Kurutholai-para{
    font-family: "Syne", sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 2;
    color: #444444; 
    margin-top: 30px;
}

.read-more-button {
    display: flex;                  
    align-items: center;            
    background: none;               
    border: none;                   
    color: #8B6914;               
    font-size: 15px;               
    font-weight: bold;             
    cursor: pointer;                
    padding: 10px 0px;             
    text-transform: uppercase; 
    text-decoration: none;      
    font-family: "Archivo Narrow", sans-serif ;   
}

.lines {
    width: 20px;                   
    height: 1px;                   
    background-color: #8B6914;      
    margin-right: 10px;           
}


.read-more-btn:hover .lines {
    background-color: #5B4D0A;    
}

.read-more-btn:hover {
    color: #5B4D0A;     
}

.img-container img {
    max-width: 100%;
    height: auto;
    align-self: center;
    object-fit: cover;
}

@media screen and (min-width: 1700px) {
    .ab-container{
      max-width: 1500px;
    }
    
}
@media screen and (min-width: 1024px) and (max-width: 1270px){
    .our-wedding{
        font-size: 40px;
    }
    .Kurutholai-para {
        font-size: 16.5px;
        margin-top: 12px;
    }
    .read-more-button {
        font-size: 14px;
    }
    .Kurutholai-head{
        font-size: 28px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .our-wedding{
        font-size: 35px;
    }
    .Kurutholai-para {
        font-size: 13px;
        margin-top: 12px;
    }
    .read-more-button {
        font-size: 14px;
    }
    .Kurutholai-head{
        font-size: 24px;
    }
}
@media screen and (min-width: 440px) and (max-width:768px){

    .read-more-button{
        font-size:13px;
    }
    .our-wedding{
       font-size: 32px;
      
    }
    .Kurutholai-para{
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .Kurutholai-head{
        font-size: 17px;
        margin-top: 0px;
    }
    .read-more-button{
        font-size: 10px;
        padding: 10px 0;
    }
    .img-container img {
        max-width: 100%;
        height: 250px;
       
        object-fit: cover;
    }
    .hall-main{
        margin-top: 50px;
    }
    
}
@media screen and (max-width:440px) {
    
    .our-wedding{
       font-size: 25px;
      
    }
    .Kurutholai-para{
        font-size: 13px;
        margin-top: 15px;
        margin-bottom: 0;
    }
    .Kurutholai-head{
        font-size: 18px;
        margin-top: 24px;
    }
    .read-more-button{
        font-size: 10px;
        padding: 10px 0;
    }
    .img-container img {
        max-width: 100%;
        height: 250px;
       
        object-fit: cover;
    }
    .hall-main{
        margin-top: 50px;
    }
    
}