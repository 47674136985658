@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Varela+Round&display=swap');

*{
    margin: 0;
    padding: 0;
}
.faci{
    overflow: hidden;
}
/* --Heading Container Start--- */
p{
    font-family: "Syne", sans-serif;
  }
.main-container{
    padding: 40px 50px 0 50px;
}
.heading-container{
    padding: 10px;
}
.specific{
    height: 400px;
    height: 100%;
}
.heading-name{
    font-family: "Varela Round", sans-serif;
    font-size: 45px;
}
.imgdecor{
    width:25%; 
}

/* --Facilities Container Start--- */

.memberimage{
    width:10%;
}

.poomalai{
    font-family: "Varela Round", sans-serif;
    font-weight: 500;
    font-size: 20px;
}
.poomalaipara{
    font-family: "Syne", sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #444444; 
}
.members,.chair{
    font-family: "Varela Round", sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #242424;
    
}

@media screen and (min-width: 1700px) {
    .main-container{
      max-width: 1500px;
    }
    
  }
/* ---------------- Media Query ----------------*/

/* @media (min-width: 1801px){
    .heading-name{
        font-family: "Varela Round", sans-serif;
        font-size: 65px;
        text-align: start;
    }
    .imgdecor{
        width:20%; 
    }
    .poomalai{
        font-family: "Varela Round", sans-serif;
        font-size: 40px;
    }
    .poomalaipara{
        font-family: "Kumbh Sans",  sans-serif;
        font-weight: 300;
        font-size: 25px;
        padding-right: 20px;

    }
    .members,.chair{
        font-family: "poppins",  sans-serif;
        font-weight: 600;
        font-size: 22px;
        color: #242424;
        padding-left: 20px;
        
    }
    .memberimage{
        object-fit: cover;
        
    }
    .facilities-contain{
        padding-left: 30px;
    }
    
} */

@media (min-width: 1440px) and (max-width: 1800px) {
    .heading-name{
        font-family: "Varela Round", sans-serif;
        font-size: 45px;
        text-align: start;
    }
    .imgdecor{
        width:22%; 
    }
    .facilities-contain{
        padding-left: 20px;
    }
    .poomalai{
        
        font-size: 28px;
    }
    .poomalaipara{
        font-family: "Syne", sans-serif;
        font-weight: 300;
        font-size: 14px;
        padding-right: 20px;

    }
    .members,.chair{
        font-family: "Syne", sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #454545;
        padding-left: 8px;
        
    }
    
}

@media (min-width: 1025px) and (max-width: 1339px){
    .main-container{
        padding: 0px 20px;
    }
    .heading-name{
        font-family: "Varela Round", sans-serif;
        font-size: 40px;
        text-align: start;
    }
    .imgdecor{
        width:24%; 
    }
    .facilities-contain{
        padding-left: 20px;
    }
    .poomalai{
        
        font-size: 25px;
    }
    .poomalaipara{
        font-weight: 300;
        font-size: 15px;
        padding-right: 20px;

    }
    .members,.chair{
        font-family: "Syne", sans-serif;
        font-weight: 600;
        font-size: 14px;
        color: #242424;
        padding-left: 10px;
        
    }
}

@media (min-width: 820px) and (max-width: 1270px) {
    .main-container{
        padding: 0px 50px;
    }
    .heading-container{
        padding: 10px;
        text-align: start;
        
    }
    .heading-name{
        font-family: "Varela Round", sans-serif;
        font-size: 32px;
        text-align: start;
    }
 
        .imgdecor{
        width:26%; 
    }
    .poomalai{
        
        font-size: 22px;
        text-align: start;
        margin-top: 20px;
    }
    .poomalaipara{
        
        font-weight: 300;
        font-size: 13px;
        text-align: justify;
        
    }
    .memberimage{
        width: 25px;
        height: 25px;
    }
    .facilities-icons{
        justify-content: start;
    }
    .members,.chair{
        
        font-weight: 600;
        font-size: 11px;
        color: #242424;
        padding-left: 6px;
        
        
    }
}

@media (max-width: 820px) {
    .main-container{
        padding: 0px 25px ;
    }
    .heading-container{
        text-align: start;
        
    }
    .heading-name{
        font-size: 32px;
    }
    .imgdecor{
        width:50%; 
    }
    .poomalai{
        
        font-size: 18px;
        text-align: start;
        margin-top: 20px;
    }
    .poomalaipara{
    
        font-weight: 300;
        font-size: 12px;    
        text-align: justify;    
        
    }
    .memberimage{
        width: 25px;
        height: 25px;
    }
    .img-fluid{
        height:180px;
        width: 100%;
        object-fit: cover;
    }
    .facilities-icons{
        justify-content: start;
    }
    .members,.chair{
        font-weight: 600;
        font-size: 12px;
        color: #242424;
        padding-left: 5px;
        
        
    }
    
}

@media (max-width: 768px) {
    .imgdecor{
        width:20%; 
    }

}
@media (max-width: 440px) {
.main-container{
    padding: 0px 20px ;
    
}
.img-fluid{
    margin-top: 15px;
}
.heading-container{
    padding:10px 0 0 0;
}
.members, .chair{
    font-size: 10px;
}
.heading-name{
    font-size: 28px;
}
.img-fluid{
    height:140px;
    width: 100%;
    object-fit: cover;
}
.poomalai{
    font-size: 18px;
    margin-top: 20px;
}
}
@media (max-width: 375px) {
    .imgdecor{
        width:45%;}

}